<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>入库详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div style="opacity: 0;height: 0;">
        <el-card style="width: 1000px;margin:0 auto;" class="box-card" id="printTest">
          <el-row :gutter="30">
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">订单号：</span>
              <span class="text">{{addPurchaseForm.we_code}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">仓库：</span>
              <span class="text">{{addPurchaseForm.w_name}}({{addPurchaseForm.wp_name}})</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">经办人：</span>
              <span class="text">{{addPurchaseForm.we_agent_name}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">入库日期：</span>
              <span class="text">{{addPurchaseForm.we_date}}</span>
            </el-col>
            <el-col v-show="addPurchaseForm.p_code!=''" :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">采购单号：</span>
              <span class="text" >{{addPurchaseForm.p_code}}</span>
            </el-col>
          </el-row>

          <el-table :data="addPurchaseForm.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格" prop="ga_name">
              <template slot-scope="scope" >
                <div>{{scope.row.ga_name}}</div>
                <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}{{scope.row.gad_end_date}}</div>
              </template>
            </el-table-column>
<!--            <el-table-column label="开始时间" prop="gad_start_date"></el-table-column>-->
<!--            <el-table-column label="结束时间" prop="gad_end_date"></el-table-column>-->
            <el-table-column label="单位" prop="gu_name"></el-table-column>
            <el-table-column label="入库数量" prop="wei_number"></el-table-column>
          </el-table>

          <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
            <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >-->

            <!--        </el-col>-->
          </el-row>
          <el-row>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">备注：</span>
              <span class="text">{{addPurchaseForm.we_remark}}</span>
            </el-col>
            <!--      </el-row>-->
            <!--      <el-row>-->
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">制单人：</span>
              <span class="text">{{addPurchaseForm.add_name}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">制单时间：</span>
              <span class="text">{{addPurchaseForm.add_date}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">供应商：</span>
              <span class="text">{{addPurchaseForm.s_name}}</span>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    <el-card  class="box-card" >
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <span class="title">订单号：</span>
          <span class="text">{{addPurchaseForm.we_code}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">仓库：</span>
          <span class="text">{{addPurchaseForm.w_name}}({{addPurchaseForm.wp_name}})</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">经办人：</span>
          <span class="text">{{addPurchaseForm.we_agent_name}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">入库日期：</span>
          <span class="text">{{addPurchaseForm.we_date}}</span>
        </el-col>
        <el-col v-show="addPurchaseForm.p_code!=''" :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">采购单号：</span>
          <span class="text" >{{addPurchaseForm.p_code}}</span>
        </el-col>
      </el-row>

      <el-table :data="addPurchaseForm.goodslist" border stripe  >
        <el-table-column type="index"></el-table-column>
        <el-table-column label="商品编码" prop="ga_code"></el-table-column>
        <el-table-column label="商品名称" prop="g_name"></el-table-column>
        <el-table-column label="商品规格" prop="ga_name">
          <template slot-scope="scope" >
            <div>{{scope.row.ga_name}}</div>
<!--            <div>{{scope.row.gad_end_date}}{{scope.row.gad_end_date}}</div>-->
            <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}{{scope.row.gad_end_date}}</div>
          </template>
        </el-table-column>
<!--        <el-table-column label="开始时间" prop="gad_start_date"></el-table-column>-->
<!--        <el-table-column label="结束时间" prop="gad_end_date"></el-table-column>-->
        <el-table-column label="单位" prop="gu_name"></el-table-column>
        <el-table-column label="入库数量" prop="wei_number"></el-table-column>
      </el-table>

      <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
        <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >-->

        <!--        </el-col>-->
      </el-row>
      <el-row>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">备注：</span>
          <span class="text">{{addPurchaseForm.we_remark}}</span>
        </el-col>
        <!--      </el-row>-->
        <!--      <el-row>-->
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">制单人：</span>
          <span class="text">{{addPurchaseForm.add_name}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">制单时间：</span>
          <span class="text">{{addPurchaseForm.add_date}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">供应商：</span>
          <span class="text">{{addPurchaseForm.s_name}}</span>
        </el-col>
      </el-row>
    </el-card>
    <el-button v-if="this.$route.query.is_printing=='true'" style="float: right;margin-top: 30px;" type="primary" v-print="'#printTest'">打印</el-button>

  </div>
</template>
<script>
export default {
  data() {
    return{
      addPurchaseForm:{}
    }
  },
  methods: {
    //获取采购订单数据
    async getpurchaseList() {
      const {data: res} = await this.$http.get(`warehouseenter/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.addPurchaseForm = res.data
      // this.querInfo.s_id=res.data.s_id
      // this.getGoodsList(this.querInfo)
      // this.adddisabled=false

    },
  },
  created(){
    this.getpurchaseList()
  }
}
</script>
<style>
.el-col{
  display: flex;
}
.pirce{
  width: 200px;
}
.title{
  color: #415161;
  font-size: 13px;
}
.text{
  color: #415161;
  font-size: 13px;
}
</style>
